<template>
    <div >
        <h1>{{ $t("Home.dashboard") }}</h1>

        <!-- PROJECT -->
        <div class="cardList">
            <div v-for="project in getProjects()" v-bind:key="project" class="card" :style="'background-image: url('+project.thumbnail+')'" @click="openProject(project._id)">
                <p>{{ project.project_name }}</p>
                {{ displayDate(project.created_at) }}
            </div>
        </div>
    </div>
</template>

<script>

//STORE
import Vuex from "vuex";

import { displayDate } from "../../lib/date-time-duration";

export default {
  data () {
        return {
            recorder_url: process.env.RECORDER_URL,
        }
    },
    name: "Tableau de bord",
    
    mounted() {
        this.refresh_user_info();
        this.loadProjects(); //LOAD VIDEOS WITH CURRENT USER ID SET IN STORE
    },
    unmounted(){
        // projects: []
        this.$store.commit('CLEAR_PROJECTS')
    },
    computed: {
        ...Vuex.mapGetters([
            "isAdmin",
            "getProjects",
            "getProject"
        ])
    },
    methods : {
        displayDate,
        ...Vuex.mapActions([
            "refresh_user_info",
            "loadProjects",
            "updateProject",
            "deleteProject",
            "duplicateProject",
        ]),
        openProject(id){
            /*
            let url = this.recorder_url + "?project_id=" + id + "&language=" + this.$i18n.locale
            window.open(url, '_blank').focus();
            */
            this.$router.push({
                //path : `/edit/${id}`
                name: 'Creative Space - Mobile',
                params : {
                    project_id : id
                }
            })
        },
    }
}
</script>

<style lang="scss" src="./HomeMobile.scss" scoped></style>